
import { defineComponent } from 'vue';
import useValidate from '@vuelidate/core';
import { required, helpers } from '@vuelidate/validators';
import _ from 'lodash';

export default defineComponent({
  props: {
    isCurrent: { default: false },
    questions: {
      default: () => {
        return [] as any;
      },
    },
  },
  data() {
    return {
      v$: useValidate(),
      form: {
        reviewAnswers: [],
      },
      ratingTexts: ['Very bad', 'Disappointed', 'Fair', 'Good', 'Very Good'],
      invalidData: [] as any,
    };
  },
  validations() {
    // let form = { reviewAnswers : {}};
    // _.forEach(this.questions, function(value, key) {
    //     form.reviewAnswers[key] = {
    //         required
    //     };
    // });
    // return form;
  },
  methods: {
    isFormValid() {
      const vm = this;
      this.invalidData = [];

      _.forEach(this.questions, function (value, key) {
        if (
          !vm.form.reviewAnswers[key] ||
          typeof vm.form.reviewAnswers[key] === 'undefined' ||
          vm.form.reviewAnswers[key] === ''
        ) {
          vm.invalidData.push(key);
        }
      });

      if (this.invalidData.length) {
        return false;
      }

      return true;
    },
    submit() {
      // this.v$.$validate() // checks all inputs

      if (!this.isFormValid()) {
        return false;
      }

      var answers = [] as any;

      _.forEach(this.form.reviewAnswers, (value, key) => {
        const question = this.questions[key];
        answers.push({
          id: question.id,
          type: question.type,
          options: question.options,
          question: question.question,
          answer: value,
        });
      });

      this.$emit('submit', { review_answers: answers });
    },
    clear() {
      this.form.reviewAnswers = [];
      // this.v$.$reset();
    },
  },
});
