import { required, helpers, email } from '@vuelidate/validators';
import regexUtil from '@/utils/regex';

const mobileValidation = helpers.regex(regexUtil.mobile.generic);
const postCodeValidation = helpers.regex(regexUtil.postalCode.aus);

export const TenantDetailsValidations = {
  form: {
    first_name: {
      required: helpers.withMessage('First name is required.', required),
    },
    last_name: {
      required: helpers.withMessage('Last name is required.', required),
    },
    date_of_birth: {
      required: helpers.withMessage('Date of birth is required.', required),
    },
    mobile_number: {
      required: helpers.withMessage('Mobile number is required.', required),
      mobileValidation: helpers.withMessage(
        'Mobile is not a valid format',
        mobileValidation
      ),
    },
    email: {
      required: helpers.withMessage('Email is required.', required),
      email: helpers.withMessage('Email is not a valid format', email),
    },
  },
};

export const PropertyDetailsValidations = {
  form: {
    post_code: {
      required: helpers.withMessage('Post code is required.', required),
      postCodeValidation: helpers.withMessage(
        'Postal code is not a valid format. Ex. XXXX',
        postCodeValidation
      ),
    },
    state: { required: helpers.withMessage('State is required.', required) },
    address_line: {
      required: helpers.withMessage('Address is required.', required),
    },
    city: { required: helpers.withMessage('City is required.', required) },
    country: {
      required: helpers.withMessage('Country is required.', required),
    },
    move_in_date: {
      required: helpers.withMessage('Move in date is required.', required),
    },
    move_out_date: {
      required: helpers.withMessage('Move out date is required.', required),
    },
    rent_per_week: {
      required: helpers.withMessage('Rent per week is required.', required),
    },
  },
};
