
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    tenant: {
      default: () => {
        return {} as any;
      },
    },
  },
  emits: ['use'],
  methods: {
    useInfo() {
      this.$emit('use', this.tenant);
    },
  },
});
