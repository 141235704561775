
import { defineComponent } from 'vue';
import InputTag from '@/components/InputTag.vue';
import { email as emailRegex } from '@/utils/regex';
import { mapActions, mapGetters } from 'vuex';
import { Getters, Actions } from '@/store/enums/TenantReviewEnums';
import toasts from '@/utils/toasts';
import { ElMessage } from 'element-plus';

export default defineComponent({
  components: { InputTag },
  props: {
    link: {
      type: String,
      default: '',
    },
    refNo: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      emails: [],
      isSendingEmail: false,
    };
  },
  computed: {
    ...mapGetters({
      actionError: Getters.GET_TENANT_REVIEW_ACTION_ERROR,
    }),
    emailValidRegex() {
      return emailRegex;
    },
  },
  methods: {
    ...mapActions({
      sendToEmails: Actions.SEND_TENANT_REVIEW_TO_EMAILS,
    }),
    copyLink() {
      navigator.clipboard.writeText(this.link);
      ElMessage({
        message: 'Link copied.',
        type: 'success',
      });
    },
    clickSubmitNewTenantReview() {
      this.$emit('createNewTenantReview', true);
    },
    clickSendReviewToEmails() {
      if (!this.refNo || !this.emails.length) {
        return false;
      }

      this.isSendingEmail = true;

      const payload = {
        refNo: this.refNo,
        emails: Object.values(this.emails),
      };

      this.sendToEmails(payload)
        .then(() => {
          toasts.success('Tenant Review Successfully Sent');
          this.emails = [];
        })
        .catch(() => {
          const { errors, message } = this.actionError;
          toasts.error(errors, message);
        })
        .finally(() => {
          this.isSendingEmail = false;
        });
    },
  },
});
