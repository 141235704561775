
import { defineComponent } from 'vue';
import Navigator from '@/views/new-design/layout/Navigator.vue';
import Stepper from '@/views/new-design/components/Stepper.vue';
import TenantDetails from '@/views/new-design/pages/SubmitTenantReview/TenantDetails.vue';
import PropertyDetails from '@/views/new-design/pages/SubmitTenantReview/PropertyDetails.vue';
import TenantReview from '@/views/new-design/pages/SubmitTenantReview/TenantReview.vue';
import UploadLedger from '@/views/new-design/pages/SubmitTenantReview/UploadLedger.vue';
import SubmittedReview from '@/views/new-design/pages/SubmitTenantReview/SubmittedReview.vue';
import Suggestion from '@/views/new-design/pages/SubmitTenantReview/Suggestion.vue';
import { Getters, Actions } from '@/store/enums/TenantReviewEnums';
import {
  Getters as RQGetters,
  Actions as RQActions,
} from '@/store/enums/ReviewQuestionsEnums';
import {
  Getters as TGetters,
  Actions as TActions,
} from '@/store/enums/TenantEnums';
import { mapGetters, mapActions } from 'vuex';
import {
  Getters as ReferenceRequestGetters,
  Actions as ReferenceRequestActions,
} from '@/store/enums/ReferenceRequestEnums';
import toasts from '@/utils/toasts';
import moment from 'moment';
import _ from 'lodash';

export default defineComponent({
  components: {
    Navigator,
    Stepper,
    Step1: TenantDetails,
    Step2: PropertyDetails,
    Step3: TenantReview,
    Step4: UploadLedger,
    Submitted: SubmittedReview,
    Suggestion,
  },
  data: () => {
    return {
      currentStep: 1,
      formData: {},
      reviewFormErrorMessages: [] as any as [],
      maxStep: 4,
      isSubmitted: true,
      referenceNo: null as any,
      referenceLink: null as any,
      isSearching: false as any,
      loading: false,
      debounceFindExistingTenant: Function() as any,
    };
  },
  computed: {
    ...mapGetters({
      actionErrors: Getters.GET_TENANT_REVIEW_ACTION_ERROR,
      tenantReview: Getters.GET_TENANT_REVIEW,
      reviewQuestions: RQGetters.GET_REVIEW_QUESTIONS,
      ledgerFile: Getters.GET_TENANT_REVIEW_LEDGER,
      uploadFileErrors: Getters.GET_UPLOAD_FILE_ERROR,
      tenantReference: ReferenceRequestGetters.GET_TENANT_REFERENCE,
      tenant: TGetters.GET_TENANT,
      existingTenant: TGetters.GET_EXISTING_TENANT,
    }),
    showingSuggestion() {
      return (
        this.existingTenant?.id && this.currentStep == 1 && this.isSearching
      );
    },
  },
  methods: {
    ...mapActions({
      uploadTenantLedger: Actions.UPLOAD_TENANT_REVIEW_LEDGER,
      createTenantReview: Actions.CREATE_TENANT_REVIEW,
      searchTenant: TActions.SEARCH_TENANT,
      getReviewQuestions: RQActions.FETCH_REVIEW_QUESTION,
      checkTenantReference: ReferenceRequestActions.CHECK_TENANT_REFERENCE,
      findExistingTenantById: TActions.FIND_EXISTING_TENANT_BY_ID,
      findExistingTenant: TActions.FIND_EXISTING_TENANT,
      removeStoredTenantReference:
        ReferenceRequestActions.REMOVE_STORED_REFERENCE,
    }),
    goPreviousStep() {
      if (this.currentStep > 1) {
        if (this.currentStep == 2) {
          (this.$refs.step2 as any).reset();
        }

        this.currentStep--;
      }
    },
    validateStep(data) {
      this.formData = { ...this.formData, ...data };
      this.$nextTick(() => {
        this.currentStep++;
      });
    },
    goNextStep() {
      if (this.currentStep == 1) {
        (this.$refs.step1 as any).submit();
      }

      if (this.currentStep == 2) {
        (this.$refs.step2 as any).submit();
      }

      if (this.currentStep == 3) {
        (this.$refs.step3 as any).submit();
      }
    },
    resetForm() {
      this.formData = {};
      this.isSearching = false;

      (this.$refs.step1 as any)?.clear();
      (this.$refs.step2 as any)?.clear();
      (this.$refs.step3 as any)?.clear();
      (this.$refs.step4 as any)?.handleRemove();

      this.referenceNo = null;
      this.referenceLink = null;
      this.currentStep = 1;
    },
    handleLedgerFile(file) {
      this.formData = { ...this.formData, ...{ ledger_file: file } };
    },
    async submit() {
      if (this.loading) return;

      this.loading = true;

      if (!(this.formData as any).ledger_file) {
        toasts.error({}, 'Please add the tenant ledger.', () => {
          this.loading = false;
        });
        return false;
      }

      if (this.$route.query.ref) {
        (this.formData as any).request_reference_id = this.$route.query.ref;
      }

      (this.formData as any).date_of_birth = moment(
        (this.formData as any)?.date_of_birth as string
      )
        .format('YYYY-MM-DD')
        .toString();

      (this.formData as any).move_in_date = moment(
        (this.formData as any)?.move_in_date as string,
        'YYYY-MM-DD'
      )
        .format('YYYY-MM-DD')
        .toString();

      (this.formData as any).move_out_date = moment(
        (this.formData as any)?.move_out_date as string,
        'YYYY-MM-DD'
      )
        .format('YYYY-MM-DD')
        .toString();

      (this.formData as any).ledger_file = (await this.uploadTenantLedger(
        (this.formData as any).ledger_file
      )) as string;

      this.createTenantReview(this.formData)
        .then(() => {
          this.resetForm();
          this.loading = false;

          this.referenceNo = this.tenantReview.reference_no;
          this.referenceLink = `${process.env.VUE_APP_BASE_URL}/tenant-report/${this.tenantReview.reference_no}`;

          this.isSubmitted = true;
        })
        .catch(() => {
          this.loading = false;
          if (this.actionErrors != null) {
            const { errors, message } = this.actionErrors;
            toasts.error(errors, message);
          }
        });
    },
    async checkTenantRef(id) {
      await this.checkTenantReference({ id: id });
    },
    async findTenantById(id) {
      await this.findExistingTenantById(id);
    },
    async loadInitialData() {
      this.getReviewQuestions();
      this.resetForm();

      this.isSubmitted = false;

      if (this.$route.query.ref) {
        await this.checkTenantRef(this.$route.query.ref);
        const data = this.tenantReference.data;

        var tenant = {
          first_name: data.tenant_first_name,
          last_name: data.tenant_last_name,
          date_of_birth: data.tenant_date_of_birth,
        };

        var property = {
          city: data.city,
          address: data.address_line,
          state: data.state,
          country: data.country,
          post_code: data.post_code,
        };

        (this.$refs.step1 as any).setInitialData(tenant);
        (this.$refs.step2 as any).setInitialData(property);
      }

      if (this.$route.query.tenant) {
        await this.findTenantById(this.$route.query.tenant);

        this.isSearching = false;
        (this.$refs.step1 as any).setInitialData(this.tenant);
      }
    },
    handleCreateNewTenantReview() {
      this.isSubmitted = false;
      this.showingSuggestion = false;
      this.resetForm();
      this.$router.push(this.$route.path);
    },
    async handleFindExistingTenant(payload) {
      this.isSearching = true;
      await this.findExistingTenant(payload);
    },
    useExistingTenant(data) {
      this.isSearching = false;
      (this.$refs.step1 as any).setInitialData(data);
    },
  },
  created() {
    this.debounceFindExistingTenant = _.debounce((payload) => {
      this.handleFindExistingTenant(payload);
    }, 1000);
  },
  mounted() {
    this.removeStoredTenantReference();
    this.loadInitialData();
  },
});
