
import { defineComponent } from 'vue';
import useValidate from '@vuelidate/core';
import toasts from '@/utils/toasts';

export default defineComponent({
  props: {
    isCurrent: { default: false },
  },
  data() {
    return {
      v$: useValidate(),
    };
  },
  emits: ['uploadLedgerFile'],
  methods: {
    handleRemove() {
      this.$emit('uploadLedgerFile', null);
    },
    onChange(file) {
      let valid = this.validateFile(file);
      if (valid) {
        this.$emit('uploadLedgerFile', file.raw);
      }
    },
    validateFile(file) {
      if (file.raw.type !== 'application/pdf') {
        toasts.error({}, 'The file must be pdf only!');
        (this.$refs.uploader as any).handleRemove();
        return false;
      }

      if (file.raw.size / 1024 / 1024 > 5) {
        toasts.error({}, 'PDF file size can not exceed 5MB!');
        (this.$refs.uploader as any).handleRemove();
        return false;
      }

      return true;
    },
  },
});
