
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    currentStep: { default: 1 },
  },
  data() {
    return {
      steps: [
        { step: 1, title: 'Tenant Details' },
        { step: 2, title: 'Tenant Property Details' },
        { step: 3, title: 'Tenant Rental Review' },
        { step: 4, title: 'Upload Tenant Ledger' },
      ],
    };
  },
});
