import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "row w-100 question-container" }
const _hoisted_2 = { class: "text" }
const _hoisted_3 = {
  class: "d-inline-block",
  style: {"margin-left":"10px"}
}
const _hoisted_4 = ["onUpdate:modelValue"]
const _hoisted_5 = {
  key: 0,
  class: "invalid-feedback"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_rate = _resolveComponent("el-rate")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_radio = _resolveComponent("el-radio")!
  const _component_el_radio_group = _resolveComponent("el-radio-group")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["justify-content-start align-items-start", { current: _ctx.isCurrent }]),
    "data-kt-stepper-element": "content"
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.questions, (question, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "col-lg-12 question-item",
          key: index
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(["d-flex align-items-center d-flex justify-content-between flex-wrap", { 'is-invalid': _ctx.invalidData.includes(index) }])
          }, [
            _createElementVNode("span", _hoisted_2, [
              _createTextVNode(_toDisplayString(index + 1) + ".) ", 1),
              _createElementVNode("span", _hoisted_3, _toDisplayString(question.question), 1)
            ]),
            (question.type == 'rating')
              ? (_openBlock(), _createBlock(_component_el_form_item, {
                  key: 0,
                  prop: question.id,
                  ref_for: true,
                  ref: question.id
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_rate, {
                      texts: _ctx.ratingTexts,
                      size: "small",
                      "show-text": "",
                      class: "rating-size",
                      "data-type": question.type,
                      "data-question": question.question,
                      "data-options": question.options,
                      modelValue: _ctx.form.reviewAnswers[index],
                      "onUpdate:modelValue": ($event: any) => ((_ctx.form.reviewAnswers[index]) = $event)
                    }, null, 8, ["texts", "data-type", "data-question", "data-options", "modelValue", "onUpdate:modelValue"])
                  ]),
                  _: 2
                }, 1032, ["prop"]))
              : (question.type == 'radio')
                ? (_openBlock(), _createBlock(_component_el_form_item, {
                    key: 1,
                    prop: question.id,
                    ref_for: true,
                    ref: question.id,
                    class: "d-flex justify-content-end"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_radio_group, {
                        modelValue: _ctx.form.reviewAnswers[index],
                        "onUpdate:modelValue": ($event: any) => ((_ctx.form.reviewAnswers[index]) = $event)
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(question.options, (option, optionIndex) => {
                            return (_openBlock(), _createBlock(_component_el_radio, {
                              key: optionIndex,
                              label: option,
                              name: question.id
                            }, null, 8, ["label", "name"]))
                          }), 128))
                        ]),
                        _: 2
                      }, 1032, ["modelValue", "onUpdate:modelValue"])
                    ]),
                    _: 2
                  }, 1032, ["prop"]))
                : _withDirectives((_openBlock(), _createElementBlock("textarea", {
                    key: 2,
                    "onUpdate:modelValue": ($event: any) => ((_ctx.form.reviewAnswers[index]) = $event),
                    class: "form-control",
                    name: "",
                    id: "",
                    cols: "30",
                    rows: "10"
                  }, null, 8, _hoisted_4)), [
                    [_vModelText, _ctx.form.reviewAnswers[index]]
                  ])
          ], 2),
          (_ctx.invalidData.includes(index))
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, " This review question is required. "))
            : _createCommentVNode("", true)
        ]))
      }), 128))
    ])
  ], 2))
}