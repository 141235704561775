
import { defineComponent } from 'vue';
import { required, helpers } from '@vuelidate/validators';
import useValidate from '@vuelidate/core';
import States from '@/components/forms/components/StateSelect.vue';
import Countries from '@/components/forms/components/CountriesSelect.vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import { Actions, Getters } from '@/store/enums/SystemEnums';
import { PropertyDetailsValidations } from '@/views/new-design/validations/TenantReview';

import maskUtil from '@/utils/mask';
import dates from '@/utils/dates';

export default defineComponent({
  props: {
    isCurrent: { default: false },
  },
  components: { Countries, States },
  data() {
    return {
      v$: useValidate(),
      form: {
        post_code: null,
        state: null as any,
        address_line: null,
        city: null,
        country: 'AU' as any,
        move_in_date: null,
        move_out_date: null,
        rent_per_week: null,
      },
      states: [],
    };
  },
  watch: {
    isCurrent(value) {
      if (value == 2) {
        this.v$.$reset();
      }
    },
  },
  validations() {
    return PropertyDetailsValidations;
  },
  computed: {
    ...mapGetters({
      getStates: Getters.GET_LIST_STATES,
    }),
    maskPostCode() {
      return maskUtil.postCode.aus;
    },
    dateFormat() {
      return dates.defaultFormat;
    },
  },
  methods: {
    ...mapActions({
      listStates: Actions.LIST_STATES,
    }),
    submit() {
      this.v$.$validate(); // checks all inputs

      if (this.v$.$error) {
        return false;
      }

      this.$emit('submit', this.form);
    },
    reset() {
      this.v$.$reset();
    },
    clear() {
      this.v$.$reset();

      this.form.post_code = null;
      this.form.state = null;
      this.form.address_line = null;
      this.form.city = null;
      this.form.country = 'AU';
      this.form.move_in_date = null;
      this.form.move_out_date = null;
      this.form.rent_per_week = null;
    },
    handleCountrySelect(value) {
      this.handleCountryStatesSelect(value);
      this.form.country = value;
    },
    handleStateSelect(value) {
      this.form.state = value;
    },
    async handleCountryStatesSelect(value) {
      if (value) {
        await this.listStates(value).then(async () => {
          this.states = Object.values(await this.getStates);
        });
      }
    },
    setInitialData(data) {
      this.form.city = data.city;
      this.form.address_line = data.address;
      this.form.state = data.state;
      this.form.country = data.country;
      this.form.post_code = data.post_code;
    },
  },
});
